import type { CompanySizeOptionsObject } from "../../translation-keys/company-size-option-keys.js";

const FormCompanySizeOptions: CompanySizeOptionsObject = {
  "1 (GT 10,000 emp)": "+10,000 employees",
  "2 (5,000-10,000 emp)": "5,000-9,999 employees",
  "3 (1,000-5,000 emp)": "1,000-4,999 employees",
  "4 (500-1,000 emp)": "500-999 employees",
  "5 (100-500 emp)": "100-499 employees",
  "6 (50-100 emp)": "50-99 employees",
  "7 (50-25 emp)": "25-49 employees",
  "8 (LT 25 emp)": "0-24 employees"
}; 

export default FormCompanySizeOptions;